var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-info",
    { attrs: { mode: _vm.mode, entity: _vm.entity, item: _vm.item } },
    [
      _c("form-row", {
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "sun-label-group",
                  { attrs: { text: "Contact name" } },
                  [
                    _c("sun-input", {
                      attrs: {
                        id: `${_vm.mode}-${_vm.entity}-contact-name`,
                        type: "text",
                        name: "contactName",
                        value:
                          _vm.item && _vm.item.contactName
                            ? _vm.item.contactName
                            : "",
                        "auto-validate": false,
                        "validate-on-blur": true,
                        maxlength: 50,
                        "text-error": _vm.i18nErrors.notBlank,
                        placeholder: "Catherine Wells",
                        required: "required",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "sun-label-group",
                  { attrs: { text: "Tax ID" } },
                  [
                    _c("asterix-input", {
                      attrs: {
                        id: `${_vm.mode}-${_vm.entity}-tax-id`,
                        type: "text",
                        name: "taxId",
                        maxlength: 15,
                        value: _vm.item && _vm.item.taxId ? _vm.item.taxId : "",
                        "validate-on-blur": "",
                        placeholder: "Tax ID",
                        required: "required",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("form-row", {
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "sun-label-group",
                  {
                    attrs: {
                      text: "External",
                      "text-info":
                        "An external client with custom access to the platform",
                    },
                  },
                  [
                    _c("sun-toggle", {
                      key: _vm.isExternal,
                      staticClass: "mt-4 ml-1",
                      attrs: {
                        id: `${_vm.mode}-${_vm.entity}-is-external`,
                        name: "isExternal",
                        disabled: _vm.disabledExternalToggle,
                        "auto-validate": true,
                        "text-error": _vm.i18nErrors.notBlank,
                      },
                      model: {
                        value: _vm.isExternal,
                        callback: function ($$v) {
                          _vm.isExternal = $$v
                        },
                        expression: "isExternal",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }