var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-form",
    [
      _c("template", { slot: "title" }, [_vm._v(" Users Info ")]),
      _c("template", { slot: "form" }, [
        _c(
          "div",
          { staticClass: "grid grid-cols-1 gap-5 lg:grid-cols-2" },
          _vm._l(_vm.contextRoles, function (role) {
            return _c(
              "div",
              { key: `${_vm.context}_${role}` },
              [
                _c(
                  "sun-label-group",
                  {
                    attrs: {
                      text:
                        role.id === _vm.ROLES.CLIENT.id
                          ? _vm.textFirstSelect
                          : role.name,
                      "text-info": _vm.i18nInfo.sellerAssociate,
                    },
                  },
                  [
                    _c("asterix-async-select", {
                      attrs: {
                        id: `${_vm.mode}-${_vm.entity}-${role}`,
                        name: `${_vm.mode}-${_vm.entity}-${role}`,
                        value: _vm.computedUserByRole[role.id],
                        multiple: "",
                        service: _vm.getApiService(role),
                        "add-hex-color": "orange",
                        "force-no-call": _vm.forceNoCall,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.saveValue($event, role.id)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }