var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-form",
    [
      _c("template", { slot: "title" }, [_vm._v("URL Alias")]),
      _c("template", { slot: "form" }, [
        _vm.loading
          ? _c("div", [_c("card-form-loading")], 1)
          : _c(
              "div",
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "font-bold text-left text-sm mr-1 sun-label text-gray-700",
                  },
                  [_vm._v(" Added URLs ")]
                ),
                _vm.urlAlias.length === 0
                  ? [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("exclamation-svg", {
                            staticClass: "w-10 h-10 mx-auto",
                          }),
                          _c("p", [_vm._v("No URL Alias added")]),
                          _c("p", { staticClass: "text-gray-500" }, [
                            _vm._v("Get started by creating a new alias."),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm._l(_vm.urlAlias, function (url, index) {
                  return _c("url-query-string", {
                    key: index,
                    attrs: {
                      options: _vm.optionsUpdated,
                      value: { param: url.url, value: url.type },
                      "label-left": "URL",
                      "left-placeholder": "URL",
                      "label-right": "Type",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeUrl($event, index)
                      },
                      remove: function ($event) {
                        return _vm.removeUrl(index)
                      },
                    },
                  })
                }),
                _c(
                  "div",
                  { staticClass: "w-full mt-4 md:w-auto url-creator-add" },
                  [
                    _c(
                      "sun-button",
                      {
                        staticClass:
                          "w-full text-white bg-gray-700 rounded shadow-md hover:bg-gray-900 custom-p-1 md:w-auto text-xs",
                        class: { "mx-auto": _vm.urlAlias.length === 0 },
                        attrs: {
                          disabled: _vm.buttonDisabled,
                          color: "gray",
                          variant: "pill",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addUrl()
                          },
                        },
                      },
                      [_vm._v(" + New alias ")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }