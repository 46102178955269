<template>
  <basic-info :mode="mode" :entity="entity" :item="item">
    <form-row>
      <template #left>
        <sun-label-group text="Contact name">
          <sun-input
            :id="`${mode}-${entity}-contact-name`"
            type="text"
            name="contactName"
            :value="item && item.contactName ? item.contactName : ''"
            :auto-validate="false"
            :validate-on-blur="true"
            :maxlength="50"
            :text-error="i18nErrors.notBlank"
            placeholder="Catherine Wells"
            required="required"
          />
        </sun-label-group>
      </template>
      <template #right>
        <sun-label-group text="Tax ID">
          <asterix-input
            :id="`${mode}-${entity}-tax-id`"
            type="text"
            name="taxId"
            :maxlength="15"
            :value="item && item.taxId ? item.taxId : ''"
            validate-on-blur
            placeholder="Tax ID"
            required="required"
          />
        </sun-label-group>
      </template>
    </form-row>

    <form-row>
      <template #left>
        <sun-label-group text="External" text-info="An external client with custom access to the platform">
          <sun-toggle
            :id="`${mode}-${entity}-is-external`"
            :key="isExternal"
            v-model="isExternal"
            :name="'isExternal'"
            class="mt-4 ml-1"
            :disabled="disabledExternalToggle"
            :auto-validate="true"
            :text-error="i18nErrors.notBlank"
          />
        </sun-label-group>
      </template>
    </form-row>
  </basic-info>
</template>

<script>
import { ERRORS } from '@/i18n/forms/errors';
import { INFO } from '@/i18n/forms/info';
import FormRow from '@/components/atoms/FormRow/FormRow';
import BasicInfo from './BasicInfo';
import AsterixInput from '@/components/atoms/AsterixInput';
import { CONTEXTS } from '@/model/shared/contexts';

export default {
  name: 'ClientInfo',
  components: {
    FormRow,
    BasicInfo,
    AsterixInput,
  },
  props: {
    mode: {
      type: String,
      default: () => 'new',
    },
    entity: {
      type: String,
      required: true,
    },
    context: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    i18nErrors: ERRORS.en,
    i18nInfo: INFO.en,
    formErrors: {
      name: null,
      email: null,
    },
    hasError: false,
    isExternal: null,
  }),
  computed: {
    disabledExternalToggle() {
      return this.context?.id === CONTEXTS.SOCIAL_AUDIENCE.id;
    },
  },
  created() {
    this.isExternal = this.item?.isExternal;
  },
};
</script>
