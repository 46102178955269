<template>
  <card-form>
    <template slot="title">URL Alias</template>
    <template slot="form">
      <div v-if="loading">
        <card-form-loading></card-form-loading>
      </div>
      <div v-else>
        <label class="font-bold text-left text-sm mr-1 sun-label text-gray-700"> Added URLs </label>
        <template v-if="urlAlias.length === 0">
          <div class="text-center">
            <exclamation-svg class="w-10 h-10 mx-auto" />
            <p>No URL Alias added</p>
            <p class="text-gray-500">Get started by creating a new alias.</p>
          </div>
        </template>
        <url-query-string
          v-for="(url, index) in urlAlias"
          :key="index"
          :options="optionsUpdated"
          :value="{ param: url.url, value: url.type }"
          label-left="URL"
          left-placeholder="URL"
          label-right="Type"
          @change="changeUrl($event, index)"
          @remove="removeUrl(index)"
        ></url-query-string>
        <div class="w-full mt-4 md:w-auto url-creator-add">
          <sun-button
            class="w-full text-white bg-gray-700 rounded shadow-md hover:bg-gray-900 custom-p-1 md:w-auto text-xs"
            :class="{ 'mx-auto': urlAlias.length === 0 }"
            :disabled="buttonDisabled"
            color="gray"
            variant="pill"
            @click="addUrl()"
          >
            + New alias
          </sun-button>
        </div>
      </div>
    </template>
  </card-form>
</template>

<script>
import CardForm from '@/components/atoms/CardForm';
import UrlQueryString from '@/components/molecules/shared/UrlCreator/UrlQueryString/UrlQueryString.vue';
import UrlAlias from '@/entities/ecommerce/UrlAlias';
import CardFormLoading from '@/components/atoms/CardFormLoading.vue';
import ExclamationSvg from '@/components/icons/ExclamationSvg.vue';

export default {
  name: 'UrlAliasForm',
  components: { CardForm, UrlQueryString, CardFormLoading, ExclamationSvg },
  props: {
    urlAlias: { type: Array, default: () => [] },
    options: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    urlAliasLoaded: [],
    optionsUpdated: [],
  }),
  computed: {
    buttonDisabled() {
      if (this.urlAlias.length === 0) return false;
      const lastUrlAlias = this.urlAlias[this.urlAlias.length - 1];
      return !lastUrlAlias.type || !lastUrlAlias.url;
    },
  },
  mounted() {
    this.optionsUpdated = this.options;
  },
  methods: {
    changeUrl(urlAliasChanged, index) {
      const urlAlias = this.urlAlias[index];
      urlAlias.url = urlAliasChanged.param;
      urlAlias.type = urlAliasChanged.value;
      this.urlAlias[index] = urlAlias;
      this.$emit('change', this.urlAlias);
    },
    removeUrl(index) {
      const urlAliasDeleted = this.urlAlias[index];
      this.urlAlias.splice(index, 1);
      this.$emit('remove', { urlAlias: this.urlAlias, urlAliasToDelet: urlAliasDeleted });
    },
    addUrl(newUrlAlias = { value: '', param: '' }) {
      const newUrl = new UrlAlias(undefined, newUrlAlias.param, newUrlAlias.value, this.urlAlias.length);
      this.urlAlias.push(newUrl);
    },
  },
};
</script>
