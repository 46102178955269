var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-form",
    [
      _c("template", { slot: "title" }, [_vm._v("Salesforce Account")]),
      _c(
        "template",
        { slot: "form" },
        [
          _c("form-row", {
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      {
                        staticClass: "flex-1 w-full",
                        attrs: { text: "Account Name" },
                      },
                      [
                        _c("asterix-async-select", {
                          attrs: {
                            id: `${_vm.entity}-account-name`,
                            name: "salesforceAccount",
                            service: _vm.getSalesforceAccounts,
                            "add-hex-color": "orange",
                          },
                          model: {
                            value: _vm.salesforceAccount,
                            callback: function ($$v) {
                              _vm.salesforceAccount = $$v
                            },
                            expression: "salesforceAccount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "right",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Account Manager" } },
                      [
                        _c("asterix-async-select", {
                          attrs: {
                            id: `${_vm.entity}-account-manager`,
                            name: "salesforceUser",
                            service: _vm.getSalesforceUsers,
                            "add-hex-color": "orange",
                          },
                          model: {
                            value: _vm.salesforceUser,
                            callback: function ($$v) {
                              _vm.salesforceUser = $$v
                            },
                            expression: "salesforceUser",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }