<template>
  <card-form>
    <template slot="title"> Salesforce Connection </template>
    <template slot="form">
      <form-row>
        <template #left>
          <sun-label-group text="Region">
            <asterix-async-select
              id="region-select"
              name="regionOffice"
              label="name"
              :value="value"
              :service="getRegionOffices"
              add-hex-color="orange"
              required="required"
              @change="$emit('input', $event)"
            />
          </sun-label-group>
        </template>
      </form-row>
    </template>
  </card-form>
</template>

<script>
import FormRow from '@/components/atoms/FormRow/FormRow';
import CardForm from '@/components/atoms/CardForm';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import { getRegionOffices } from '@/services/modules/dashboardSSP/office';

export default {
  name: 'SalesforceConnectionInfo',
  components: {
    FormRow,
    CardForm,
    AsterixAsyncSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    getRegionOffices,
  },
};
</script>
