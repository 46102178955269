var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-form",
    [
      _c("template", { slot: "title" }, [_vm._v(" Address Info ")]),
      _c(
        "template",
        { slot: "form" },
        [
          _c("form-row", {
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Street" } },
                      [
                        _c("sun-input", {
                          attrs: {
                            id: `${_vm.mode}-${_vm.entity}-street`,
                            type: "text",
                            name: "street",
                            value:
                              _vm.data && _vm.data.street
                                ? _vm.data.street
                                : "",
                            "auto-validate": false,
                            "validate-on-blur": true,
                            maxlength: 120,
                            "text-error": _vm.i18nErrors.notBlank,
                            placeholder: "135 W. 50th Street",
                            required: "required",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "right",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "City" } },
                      [
                        _c("sun-input", {
                          attrs: {
                            id: `${_vm.mode}-${_vm.entity}-city`,
                            type: "text",
                            name: "city",
                            value:
                              _vm.data && _vm.data.city ? _vm.data.city : "",
                            "auto-validate": false,
                            "validate-on-blur": true,
                            maxlength: 50,
                            "text-error": _vm.i18nErrors.notBlank,
                            placeholder: "New York City",
                            required: "required",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("form-row", {
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Country" } },
                      [
                        _c("sun-select", {
                          attrs: {
                            id: `${_vm.mode}-${_vm.entity}-country`,
                            name: "country",
                            value: _vm.actualCountry,
                            options: _vm.countries,
                            "track-by": "id",
                            label: "name",
                            "add-hex-color": "orange",
                            "text-error": _vm.i18nErrors.selectOption,
                            required: "required",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "right",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "County" } },
                      [
                        _c("sun-input", {
                          attrs: {
                            id: `${_vm.mode}-${_vm.entity}-county`,
                            type: "text",
                            name: "county",
                            value:
                              _vm.data && _vm.data.county
                                ? _vm.data.county
                                : "",
                            "auto-validate": false,
                            "validate-on-blur": true,
                            maxlength: 50,
                            placeholder: "New York county",
                            "text-error": _vm.i18nErrors.notBlank,
                            required: "required",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("form-row", {
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Postal Code" } },
                      [
                        _c("sun-input", {
                          attrs: {
                            id: `${_vm.mode}-${_vm.entity}-postal-code`,
                            type: "text",
                            name: "postalCode",
                            value:
                              _vm.data && _vm.data.postalCode
                                ? _vm.data.postalCode
                                : "",
                            placeholder: "55555",
                            "text-error": _vm.i18nErrors.notBlank,
                            "auto-validate": false,
                            "validate-on-blur": true,
                            maxlength: 20,
                            required: "required",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }