import { ROLES } from '@/model/shared/roles';

const CONFIG = {
  types: ['Sunmedia', 'External'],
  acceptRoles: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id],
  urlCreatorOptions: ['TRACK', 'DASHBOARD', 'CDN'].map(macro => ({
    name: macro,
    value: macro,
  })),
};

export default CONFIG;
