<template>
  <card-form>
    <template slot="title"> Users Info </template>
    <template slot="form">
      <div class="grid grid-cols-1 gap-5 lg:grid-cols-2">
        <div v-for="role in contextRoles" :key="`${context}_${role}`">
          <sun-label-group
            :text="role.id === ROLES.CLIENT.id ? textFirstSelect : role.name"
            :text-info="i18nInfo.sellerAssociate"
          >
            <asterix-async-select
              :id="`${mode}-${entity}-${role}`"
              :name="`${mode}-${entity}-${role}`"
              :value="computedUserByRole[role.id]"
              multiple
              :service="getApiService(role)"
              add-hex-color="orange"
              :force-no-call="forceNoCall"
              @change="saveValue($event, role.id)"
            />
          </sun-label-group>
        </div>
      </div>
    </template>
  </card-form>
</template>

<script>
import { INFO } from '@/i18n/forms/info';
import CardForm from '@/components/atoms/CardForm';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import { CoreApi } from '@/services/api/CoreApi';
import { deepClone } from '@/utils/deepClone';
import { CONTEXTS } from '@/model/shared/contexts';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getUsers } from '@/services/modules/Core/user/getUsers';
import { ROLES } from '@/model/shared/roles';

export default {
  name: 'UsersInfo',
  components: {
    CardForm,
    AsterixAsyncSelect,
  },
  props: {
    mode: {
      type: String,
      default: () => 'new',
    },
    entity: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    context: {
      type: String,
      default: () => '',
    },
    forceNoCall: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    i18nInfo: INFO.en,
    apiToCalls: new CoreApi(),
    values: {},
    ROLES,
  }),
  computed: {
    textFirstSelect() {
      return this.context !== CONTEXTS.DASHBOARD_SSP.id ? 'Clients associated' : 'Offer Owner';
    },
    contextRoles() {
      return CONTEXTS?.[this.context]?.userRolesToLink || [];
    },
    computedUserByRole() {
      return this.contextRoles.reduce((obj, typeRole) => {
        return {
          ...obj,
          [typeRole.id]: this.value.filter(user => !!user.contextRoles.find(({ role }) => role === typeRole.id)),
        };
      }, {});
    },
  },
  beforeMount() {
    this.values = deepClone(this.computedUserByRole);
  },
  beforeDestroy() {
    this.apiToCalls.refreshCancelToken();
  },
  methods: {
    getApiService(role) {
      return (params = new QueryParamsBuilder()) => {
        params.addFilter('contextRole.role', role.id);
        params.addFilter('contextRole.context', this.context);

        return getUsers(params, { preventCancel: true });
      };
    },
    saveValue({ items }, nameCollection) {
      this.values[nameCollection] = items;
      let totalArray = [];
      Object.keys(this.values).forEach(key => {
        totalArray = totalArray.concat(this.values[key]);
      });
      this.$emit('input', totalArray);
    },
  },
};
</script>
