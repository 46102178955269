<template>
  <card-form>
    <template slot="title">Salesforce Account</template>
    <template slot="form">
      <form-row>
        <template #left>
          <sun-label-group text="Account Name" class="flex-1 w-full">
            <asterix-async-select
              :id="`${entity}-account-name`"
              v-model="salesforceAccount"
              name="salesforceAccount"
              :service="getSalesforceAccounts"
              add-hex-color="orange"
            />
          </sun-label-group>
        </template>
        <template #right>
          <sun-label-group text="Account Manager">
            <asterix-async-select
              :id="`${entity}-account-manager`"
              v-model="salesforceUser"
              name="salesforceUser"
              :service="getSalesforceUsers"
              add-hex-color="orange"
            />
          </sun-label-group>
        </template>
      </form-row>
    </template>
  </card-form>
</template>

<script>
import { mapActions } from 'vuex';
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import {
  getSalesforceUsers,
  getSalesforceUserById,
  getSalesforceAccounts,
  getSalesforceAccountById,
} from '@/services/modules/Core/salesforce';
import { ERRORS } from '@/i18n/forms/errors';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';

export default {
  name: 'SalesforceAccountInfo',
  components: {
    FormRow,
    CardForm,
    AsterixAsyncSelect,
  },
  props: {
    entity: {
      type: String,
      required: true,
    },
    account: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    salesforceAccount: null,
    salesforceUser: null,
    i18nErrors: ERRORS.en,
    formErrors: { nameAccount: null },
  }),
  watch: {
    account: {
      immediate: true,
      async handler(val) {
        if (val?.id) {
          try {
            const { data } = await getSalesforceAccountById(val.id);
            this.salesforceAccount = data;
          } catch (error) {
            if (error.code !== HTTPStatusCode.Cancel) {
              this.createToast(Toast.error(`Can't get salesforce account.`, error.message));
            }
          }
        }
      },
    },
    user: {
      immediate: true,
      async handler(val) {
        if (val?.id) {
          try {
            const { data } = await getSalesforceUserById(val.id);
            this.salesforceUser = data;
          } catch (error) {
            if (error.code !== HTTPStatusCode.Cancel) {
              this.createToast(Toast.error(`Can't get salesforce user.`, error.message));
            }
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    getSalesforceUsers,
    getSalesforceAccounts,
  },
};
</script>
