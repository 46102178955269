import { api } from '.';
import { SALESFORCE_REGION_OFFICES } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

export const getResources = () => ({
  [SALESFORCE_REGION_OFFICES]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:DashboardSSPClient, meta: import('@/model/shared/ResponseBuilder').EndpointMeta}}>}
 */
export async function getRegionOffices(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());

  const { data } = await api.get(partialUrl, params);
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
