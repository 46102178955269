import { ALIAS_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import { api } from '.';

export const getResources = (clientId, aliasId) => ({
  [CLIENT_RESOURCE]: clientId,
  [ALIAS_RESOURCE]: aliasId,
});

export async function deleteUrlAlias(clientId, urlAlias) {
  const partialUrl = api.createUrl(getResources(clientId, urlAlias.id));
  await api.delete(partialUrl, urlAlias);
  return urlAlias.id;
}
