<template>
  <asterix-form-modal
    :title="title"
    closable
    modal-class="sm:max-w-3xl"
    data-testid="workspace-modal"
    @submit="save"
    @cancel="$emit('cancel')"
  >
    <template #content>
      <div class="content-height overflow-auto pb-6">
        <div v-if="showForm" class="bg-white flex flex-col p-6 rounded-lg shadow-md text-left">
          <form-row>
            <sun-label-group text="Context" class="w-full">
              <sun-select
                v-model="context"
                add-hex-color="orange"
                :disabled="!!clientId"
                label="name"
                :options="availableContexts"
                placeholder="Select context"
                required="required"
                text-error="This field is required"
                track-by="name"
                @change="changeContextSelector"
              />
              <!-- We are not allowed to edit client context once it is set -->
            </sun-label-group>
          </form-row>
          <client-info class="mt-12" :mode="mode" entity="client" :context="context" :item="client" :types="types" />
          <users-info
            v-if="showUsersInfo"
            :value="client.users"
            class="mt-12"
            mode="new"
            entity="client"
            :context="context.id"
            @input="clientUsers = $event"
          />
          <asterix-address-form
            v-if="!isDashboardContext"
            :mode="mode"
            entity="client"
            :data="client && client.address ? client.address : {}"
          />
          <salesforce-connection-info v-if="isDashboardContext" :value="client.regionOffice" />
          <salesforce-account-info
            v-if="isSocialAudienceContext"
            :user="client.salesforceUser"
            :account="client.salesforceAccount"
            entity="client"
          />
          <url-alias-form
            v-if="isEcommerceContext"
            :url-alias="urlAlias"
            :options="urlCreatorOptions"
            :loading="loadingUrlAlias"
            @change="onChangeUrlAlias"
            @remove="onRemoveUrlAlias"
          >
          </url-alias-form>
          <custom-logos-form
            v-if="isEcommerceContext && customImages"
            :default-images="customImages"
            :loading="loadingUrlAlias"
            @change="onImagesChange"
          >
          </custom-logos-form>
        </div>
        <div v-if="!showForm">
          <card-form-loading></card-form-loading>
          <card-form-loading :rows="4" class="mt-10"></card-form-loading>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap justify-between sm:px-3">
        <save-button id="user-submit" variant="pill" class="mb-3 sm:mb-0 button" :disabled="loading" />
        <sun-button variant="pill" class="w-full text-sm custom-p-1 sm:w-auto" color="white" @click="$emit('cancel')">
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-form-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ClientFactory from '@/entities/shared/ClientFactory';

import SaveButton from '@/components/atoms/SaveButton';
import ClientInfo from '@/components/organisms/shared/formFields/ClientInfo';
import UsersInfo from '@/components/organisms/shared/formFields/UsersInfo';
import { AVAILABLE_CONTEXTS, CONTEXTS } from '@/model/shared/contexts';
import AsterixAddressForm from '@/components/molecules/shared/AsterixAddressForm';
import Address from '@/entities/Address';
import CONFIG from './config';
import metaInfo from '@/mixins/common/metaInfo';
import { loadMixin } from '@/mixins/common/load';
import { IS_LOADING, SET_LOAD } from '@/store/modules/load/keys';
import { SET_ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { AUTH_USER_AVAILABLE_CONTEXTS } from '@/store/modules/auth/keys';
import FormRow from '@/components/atoms/FormRow/FormRow.vue';
import { getClientById as getCoreClient } from '@/services/modules/Core/client/getClientById';
import * as ThirdPartyClientApi from '@/services/modules/ThirdParty/client';
import * as EcommerceClientApi from '@/services/modules/ecommerce/client';
import * as DashboardSSPClientApi from '@/services/modules/dashboardSSP/client';
import * as SocialAudienceClientApi from '@/services/modules/socialAudience/client';
import * as SunStudioClientApi from '@/services/modules/sunStudio/client';
import * as ContextualClientApi from '@/services/modules/contextual/client';
import CardFormLoading from '@/components/atoms/CardFormLoading.vue';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import { workspace as workspaceRoute } from '@/router/private/modules/core/workspace';

import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import SalesforceConnectionInfo from '@/components/organisms/shared/formFields/SalesforceConnectionInfo.vue';
import RegionOffice from '@/entities/dashboardSSP/RegionOffice';
import SalesforceAccountInfo from '@/components/organisms/shared/formFields/SalesforceAccountInfo.vue';
import apiRequest from '@/utils/apiRequest';
import UrlAliasForm from '@/components/organisms/shared/formFields/UrlAliasForm.vue';
import CustomLogosForm from '@/components/organisms/shared/formFields/CustomLogosForm.vue';

import { getUrlAlias } from '@/services/modules/ecommerce/urlAlias';
import { createUrlAlias } from '@/services/modules/ecommerce/urlAlias/createUrlAlias';
import { updateUrlAlias } from '@/services/modules/ecommerce/urlAlias/updateUrlAlias';
import { deleteUrlAlias } from '@/services/modules/ecommerce/urlAlias/deleteUrlAlias';

import { deepClone } from '@/utils/deepClone';

const CoreClient = ClientFactory.create(CONTEXTS.CORE.id);

export default {
  name: 'WorkspaceModal',
  components: {
    AsterixFormModal,
    SaveButton,
    AsterixAddressForm,
    ClientInfo,
    UsersInfo,
    FormRow,
    CardFormLoading,
    SalesforceConnectionInfo,
    SalesforceAccountInfo,
    UrlAliasForm,
    CustomLogosForm,
  },
  mixins: [loadMixin, metaInfo],
  props: {
    clientId: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    types: CONFIG.types,
    loading: false,
    formErrors: {
      name: null,
      email: null,
    },
    hasError: false,
    client: new CoreClient(),
    loadingClient: true,
    showDeleteModal: false,
    context: null,
    clientUsers: [],
    salesforceAccountInfo: {
      accountName: null,
      accountSalesforceId: null,
    },
    urlAlias: [],
    previousUrlAlias: [],
    loadingUrlAlias: false,
    customImages: null,
  }),
  computed: {
    ...mapGetters({
      isLoading: IS_LOADING,
      authUserAvailableContexts: AUTH_USER_AVAILABLE_CONTEXTS,
    }),
    isEditMode() {
      return !!this.clientId;
    },
    mode() {
      return this.isEditMode ? 'edit' : 'new';
    },
    title() {
      return `${this.isEditMode ? 'Edit' : 'New'} Workspace`;
    },
    availableContexts() {
      return Object.values(AVAILABLE_CONTEXTS).filter(
        context => context.id !== CONTEXTS.CORE.id && this.authUserAvailableContexts.includes(context.id)
      );
    },
    clientAPI() {
      if (!this.context) return { get: getCoreClient };
      const apis = {
        [CONTEXTS.THIRD_PARTY.id]: ThirdPartyClientApi,
        [CONTEXTS.ECOMMERCE.id]: EcommerceClientApi,
        [CONTEXTS.DASHBOARD_SSP.id]: DashboardSSPClientApi,
        [CONTEXTS.SOCIAL_AUDIENCE.id]: SocialAudienceClientApi,
        [CONTEXTS.SUNSTUDIO.id]: SunStudioClientApi,
        [CONTEXTS.CONTEXTUAL.id]: ContextualClientApi,
      };
      return {
        get: apis?.[this.context.id].getClientById,
        create: apis?.[this.context.id].createClient,
        update: apis?.[this.context.id].updateClient,
      };
    },
    deleteDescription() {
      return `You are going to remove ${this.client.name}. This action can not be undone.`;
    },
    showForm() {
      return this.mode === 'new' || (this.mode === 'edit' && !!this.client.name);
    },
    isDashboardContext() {
      return this.context?.id === CONTEXTS.DASHBOARD_SSP.id;
    },
    isSocialAudienceContext() {
      return this.context?.id === CONTEXTS.SOCIAL_AUDIENCE.id;
    },
    isEcommerceContext() {
      return this.context?.id === CONTEXTS.ECOMMERCE.id;
    },
    showUsersInfo() {
      return !!this.context && !!this.context.id && this.context.id !== CONTEXTS.SUNSTUDIO.id;
    },
    urlCreatorOptions: () => CONFIG.urlCreatorOptions,
  },
  async beforeMount() {
    this.setLoad();

    if (this.clientId) {
      // Get context by client id and then set clientAPI by context
      await this.setContextAndGetClient();
      // I has client by correct context
      await this.checkModeForm();

      if (this.context === CONTEXTS.ECOMMERCE) {
        this.urlAlias = await this.loadUrlAlias(this.clientId);
        this.previousUrlAlias = deepClone(this.urlAlias);
        this.customImages = {
          faviconImage: this.client.faviconImage,
          faviconPath: this.client.faviconPath,
          faviconUrl: this.client.faviconUrl,
          backgroundDarkImage: this.client.backgroundDarkImage,
          backgroundDarkPath: this.client.backgroundDarkPath,
          backgroundDarkUrl: this.client.backgroundDarkUrl,
          backgroundLightImage: this.client.backgroundLightImage,
          backgroundLightPath: this.client.backgroundLightPath,
          backgroundLightUrl: this.client.backgroundLightUrl,
        };
      }
    }

    this.loadingClient = false;

    this.setLoad(false);
  },
  methods: {
    onDeleteWorkspace() {
      this.showDeleteModal = true;
    },
    ...mapActions({
      setActiveClient: SET_ACTIVE_CLIENT,
      setLoad: SET_LOAD,
      createToast: CREATE_TOAST,
    }),
    async setContextAndGetClient() {
      this.loading = true;

      await apiRequest(async () => {
        const coreClient = await this.clientAPI.get(this.clientId);
        this.context = CONTEXTS[coreClient.type];
      }).catch(() => {
        this.hasError = true;
      });

      this.loading = false;
    },
    async checkModeForm() {
      this.loading = true;

      await apiRequest(async () => {
        const { data } = await this.clientAPI.get(this.clientId);
        this.client = data;
        this.clientUsers = this.client.users;
        this.updateTitle();
      }).catch(error => {
        this.hasError = true;
        this.createToast(Toast.error(`We can't get workspace`, error.message));
      });

      this.loading = false;
    },
    makeClientByContext(form) {
      const extraObject =
        this.context.id === CONTEXTS.DASHBOARD_SSP.id
          ? new RegionOffice(form.regionOffice.value.id, form.regionOffice.value.name)
          : new Address(
              form.street.value,
              form.city.value,
              form.country.value.id,
              form.county.value,
              form.postalCode.value
            );

      const Client = ClientFactory.create(this.context.id);
      const client = new Client(
        this.clientId || null,
        form.name.value,
        form.email.value,
        form.contactName.value,
        !!form.isExternal.value,
        this.clientUsers,
        form.taxId.value,
        extraObject
      );

      if (this.context.id === CONTEXTS.SOCIAL_AUDIENCE.id) {
        client.salesforceUser = form.salesforceUser.value || null;
        client.salesforceAccount = form.salesforceAccount.value || null;
        const { fee, techCost, walletType } = form;
        client.createFinance(walletType.value, fee.value, techCost.value);
      }
      if (this.isEcommerceContext) {
        client.faviconImage = this.customImages.faviconImage;
        client.faviconPath = this.customImages.faviconPath;
        client.backgroundDarkImage = this.customImages.backgroundDarkImage;
        client.backgroundDarkPath = this.customImages.backgroundDarkPath;
        client.backgroundLightImage = this.customImages.backgroundLightImage;
        client.backgroundLightPath = this.customImages.backgroundLightPath;
      }

      return client;
    },
    async save({ valid, form }) {
      if (valid) {
        try {
          this.setLoad();
          const client = this.makeClientByContext(form);
          if (this.mode === 'new') {
            await this.createClient(client);
          } else {
            await this.updateClient(client);
          }
          if (this.context.id === CONTEXTS.ECOMMERCE.id) {
            await this.updateUrlAlias(client.id);
          }
        } catch (error) {
          this.hasError = true;
        } finally {
          this.setLoad(false);
        }
      }
    },
    async goToWorkspaces() {
      await this.$router.push({
        name: workspaceRoute.name,
      });
    },
    goToDefaultRoute(client) {
      this.setActiveClient(client);
      this.$router.push({
        name: `${this.context.baseUrl}-${this.context.defaultLocation}`,
        params: { clientId: client.id },
      });
    },
    async createClient(client) {
      try {
        client.id = await this.clientAPI.create(client);
        this.createToast(Toast.success('Workspace created', `Workspace ${client.name} was created successfully.`));

        if (client.type === CONTEXTS.SUNSTUDIO.id) {
          this.$emit('cancel');
        } else {
          this.goToDefaultRoute(client);
        }
      } catch ({ message }) {
        this.createToast(Toast.error('Workspace not created', message));
        this.formErrors.email = message?.includes('email') ? message : '';
        this.formErrors.name = message?.includes('name') ? message : '';
      }
    },
    async updateClient(client) {
      try {
        await this.clientAPI.update(client);
        this.createToast(Toast.success('Workspace updated', `Workspace ${client.name} was updated successfully.`));
        await this.goToWorkspaces();
      } catch ({ message }) {
        this.createToast(Toast.error('Workspace not updated', message));
        this.formErrors.email = message?.includes('email') ? message : '';
        this.formErrors.name = message?.includes('name') ? message : '';
      }
    },
    changeContextSelector({ value: context }) {
      if (context?.id === CONTEXTS.SOCIAL_AUDIENCE.id) {
        this.client.isExternal = true;
      } else if (context?.id === CONTEXTS.ECOMMERCE.id) {
        this.customImages = {};
      } else {
        this.client.isExternal = !this.client.isExternal;
      }
    },
    onChangeUrlAlias(urlAliasChanged) {
      this.urlAlias = urlAliasChanged;
    },
    async onRemoveUrlAlias({ urlAlias, urlAliasToDelet }) {
      try {
        if (this.previousUrlAlias.find(url => url.id === urlAliasToDelet.id)) {
          await deleteUrlAlias(this.clientId, urlAliasToDelet);
          this.createToast(
            Toast.success('URL Alias successfully deleted', `The alias ${urlAliasToDelet.url} was successfully deleted`)
          );
        }
        this.urlAlias = urlAlias;
      } catch (message) {
        this.createToast(Toast.error('URL Alias not deleted', message));
      }
    },
    async loadUrlAlias(clientId) {
      try {
        this.loadingUrlAlias = true;
        const { data } = await getUrlAlias(clientId);
        return data;
      } catch (message) {
        this.createToast(Toast.error('URL Alias not loaded', message));
      } finally {
        this.loadingUrlAlias = false;
      }
    },
    updateUrlAlias(clientId) {
      this.urlAlias.forEach(async url => {
        try {
          if (
            url.id &&
            JSON.stringify(url) !== JSON.stringify(this.previousUrlAlias.find(prevurl => prevurl.id === url.id))
          ) {
            await updateUrlAlias(clientId, url);
          } else if (!url.id) {
            await createUrlAlias(clientId, url);
          }
        } catch (message) {
          this.createToast(Toast.error('URL Alias not Saved', message));
        }
      });
    },
    onImagesChange(images) {
      this.customImages = images;
    },
  },
};
</script>

<style scoped>
.content-height {
  height: 66vh;
}

::v-deep #user-submit {
  @apply ml-0;
}
</style>
