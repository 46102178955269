var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-form",
    [
      _c("template", { slot: "title" }, [_vm._v("Customize Logos")]),
      _c("template", { slot: "form" }, [
        _vm.loading
          ? _c("div", [_c("card-form-loading")], 1)
          : _c(
              "div",
              { staticClass: "w-full flex-col" },
              [
                _c(
                  "sun-label-group",
                  {
                    key: `favicon_${_vm.img_key}`,
                    attrs: { text: "Chose FavIcon" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex w-full" },
                      [
                        _vm.images && _vm.images.faviconPath
                          ? _c("div", { staticClass: "flex flex-col w-1/3" }, [
                              _c("img", {
                                staticClass: "img-class",
                                attrs: { src: _vm.images.faviconUrl },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-xs mt-1 flex items-center cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onFileUpload(
                                        [],
                                        _vm.IMGTYPES.FAVICON
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Delete "),
                                  _c("close-svg", {
                                    staticClass: "w-3 h-3 ml-1 mb-1",
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c("sun-file-uploader", {
                          attrs: { id: "file-favIcon", accept: "image/*" },
                          on: {
                            change: function ($event) {
                              return _vm.onFileUpload(
                                $event,
                                _vm.IMGTYPES.FAVICON
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "sun-label-group",
                  {
                    key: `light_${_vm.img_key}`,
                    staticClass: "mt-4",
                    attrs: { text: "Chose Logo for light background" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex w-full" },
                      [
                        _vm.images && _vm.images.backgroundLightPath
                          ? _c(
                              "div",
                              { staticClass: "flex flex-col w-1/3 relative" },
                              [
                                _c("img", {
                                  staticClass: "img-class",
                                  attrs: { src: _vm.images.backgroundLightUrl },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-xs mt-1 flex items-center cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onFileUpload(
                                          [],
                                          _vm.IMGTYPES.BG_LIGHT
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Delete "),
                                    _c("close-svg", {
                                      staticClass: "w-3 h-3 ml-1 mb-1",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("sun-file-uploader", {
                          attrs: { id: "file-bg-light", accept: "image/*" },
                          on: {
                            change: function ($event) {
                              return _vm.onFileUpload(
                                $event,
                                _vm.IMGTYPES.BG_LIGHT
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "sun-label-group",
                  {
                    key: `dark_${_vm.img_key}`,
                    staticClass: "mt-4",
                    attrs: { text: "Chose Logo for dark background" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex w-full" },
                      [
                        _vm.images && _vm.images.backgroundDarkPath
                          ? _c("div", { staticClass: "flex flex-col w-1/3" }, [
                              _c("img", {
                                staticClass: "img-class",
                                attrs: { src: _vm.images.backgroundDarkUrl },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-xs mt-1 flex items-center cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onFileUpload(
                                        [],
                                        _vm.IMGTYPES.BG_DARK
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Delete "),
                                  _c("close-svg", {
                                    staticClass: "w-3 h-3 ml-1 mb-1",
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c("sun-file-uploader", {
                          attrs: { id: "file-bg-dark", accept: "image/*" },
                          on: {
                            change: function ($event) {
                              return _vm.onFileUpload(
                                $event,
                                _vm.IMGTYPES.BG_DARK
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }