<template>
  <card-form>
    <template slot="title"> Address Info </template>
    <template slot="form">
      <form-row>
        <template #left>
          <sun-label-group text="Street">
            <sun-input
              :id="`${mode}-${entity}-street`"
              type="text"
              name="street"
              :value="data && data.street ? data.street : ''"
              :auto-validate="false"
              :validate-on-blur="true"
              :maxlength="120"
              :text-error="i18nErrors.notBlank"
              placeholder="135 W. 50th Street"
              required="required"
            />
          </sun-label-group>
        </template>
        <template #right>
          <sun-label-group text="City">
            <sun-input
              :id="`${mode}-${entity}-city`"
              type="text"
              name="city"
              :value="data && data.city ? data.city : ''"
              :auto-validate="false"
              :validate-on-blur="true"
              :maxlength="50"
              :text-error="i18nErrors.notBlank"
              placeholder="New York City"
              required="required"
            />
          </sun-label-group>
        </template>
      </form-row>

      <form-row>
        <template #left>
          <sun-label-group text="Country">
            <sun-select
              :id="`${mode}-${entity}-country`"
              name="country"
              :value="actualCountry"
              :options="countries"
              track-by="id"
              label="name"
              add-hex-color="orange"
              :text-error="i18nErrors.selectOption"
              required="required"
            />
          </sun-label-group>
        </template>
        <template #right>
          <sun-label-group text="County">
            <sun-input
              :id="`${mode}-${entity}-county`"
              type="text"
              name="county"
              :value="data && data.county ? data.county : ''"
              :auto-validate="false"
              :validate-on-blur="true"
              :maxlength="50"
              placeholder="New York county"
              :text-error="i18nErrors.notBlank"
              required="required"
            />
          </sun-label-group>
        </template>
      </form-row>

      <form-row>
        <template #left>
          <sun-label-group text="Postal Code">
            <sun-input
              :id="`${mode}-${entity}-postal-code`"
              type="text"
              name="postalCode"
              :value="data && data.postalCode ? data.postalCode : ''"
              placeholder="55555"
              :text-error="i18nErrors.notBlank"
              :auto-validate="false"
              :validate-on-blur="true"
              :maxlength="20"
              required="required"
            />
          </sun-label-group>
        </template>
      </form-row>
    </template>
  </card-form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ERRORS } from '@/i18n/forms/errors';
import { COUNTRY_NS, COUNTRIES_KEY, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import FormRow from '@/components/atoms/FormRow/FormRow';
import CardForm from '../../atoms/CardForm';

export default {
  name: 'AsterixAddressForm',
  components: {
    FormRow,
    CardForm,
  },
  props: {
    mode: {
      type: String,
      default: () => 'new',
    },
    entity: {
      type: String,
      default: () => 'no-entity',
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      i18nErrors: ERRORS.en,
    };
  },
  computed: {
    ...mapState(COUNTRY_NS, {
      countries: COUNTRIES_KEY,
    }),
    actualCountry() {
      return this.data.country ? { id: this.data.country.iso, name: this.data.country.name } : null;
    },
  },
  beforeMount() {
    this.getCountries();
  },
  methods: {
    ...mapActions({
      getCountries: GET_COUNTRIES_REQUEST,
    }),
  },
};
</script>
