var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-form",
    [
      _c("template", { slot: "title" }, [_vm._v(" Salesforce Connection ")]),
      _c(
        "template",
        { slot: "form" },
        [
          _c("form-row", {
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Region" } },
                      [
                        _c("asterix-async-select", {
                          attrs: {
                            id: "region-select",
                            name: "regionOffice",
                            label: "name",
                            value: _vm.value,
                            service: _vm.getRegionOffices,
                            "add-hex-color": "orange",
                            required: "required",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$emit("input", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }