class Address {
  constructor(street = '', city = '', country = '', county = '', postalCode = '') {
    this.street = street;
    this.city = city;
    this.country = country;
    this.county = county;
    this.postalCode = postalCode;
  }
}

export default Address;
