import { ALIAS_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import { api } from '.';

export const getResources = (clientId, aliasId) => ({
  [CLIENT_RESOURCE]: clientId,
  [ALIAS_RESOURCE]: aliasId,
});

export function updateUrlAlias(clientId, urlAlias) {
  const partialUrl = api.createUrl(getResources(clientId, urlAlias.id));
  return api.update(partialUrl, urlAlias);
}
