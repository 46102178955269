var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: {
      title: _vm.title,
      closable: "",
      "modal-class": "sm:max-w-3xl",
      "data-testid": "workspace-modal",
    },
    on: {
      submit: _vm.save,
      cancel: function ($event) {
        return _vm.$emit("cancel")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "content-height overflow-auto pb-6" }, [
              _vm.showForm
                ? _c(
                    "div",
                    {
                      staticClass:
                        "bg-white flex flex-col p-6 rounded-lg shadow-md text-left",
                    },
                    [
                      _c(
                        "form-row",
                        [
                          _c(
                            "sun-label-group",
                            {
                              staticClass: "w-full",
                              attrs: { text: "Context" },
                            },
                            [
                              _c("sun-select", {
                                attrs: {
                                  "add-hex-color": "orange",
                                  disabled: !!_vm.clientId,
                                  label: "name",
                                  options: _vm.availableContexts,
                                  placeholder: "Select context",
                                  required: "required",
                                  "text-error": "This field is required",
                                  "track-by": "name",
                                },
                                on: { change: _vm.changeContextSelector },
                                model: {
                                  value: _vm.context,
                                  callback: function ($$v) {
                                    _vm.context = $$v
                                  },
                                  expression: "context",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("client-info", {
                        staticClass: "mt-12",
                        attrs: {
                          mode: _vm.mode,
                          entity: "client",
                          context: _vm.context,
                          item: _vm.client,
                          types: _vm.types,
                        },
                      }),
                      _vm.showUsersInfo
                        ? _c("users-info", {
                            staticClass: "mt-12",
                            attrs: {
                              value: _vm.client.users,
                              mode: "new",
                              entity: "client",
                              context: _vm.context.id,
                            },
                            on: {
                              input: function ($event) {
                                _vm.clientUsers = $event
                              },
                            },
                          })
                        : _vm._e(),
                      !_vm.isDashboardContext
                        ? _c("asterix-address-form", {
                            attrs: {
                              mode: _vm.mode,
                              entity: "client",
                              data:
                                _vm.client && _vm.client.address
                                  ? _vm.client.address
                                  : {},
                            },
                          })
                        : _vm._e(),
                      _vm.isDashboardContext
                        ? _c("salesforce-connection-info", {
                            attrs: { value: _vm.client.regionOffice },
                          })
                        : _vm._e(),
                      _vm.isSocialAudienceContext
                        ? _c("salesforce-account-info", {
                            attrs: {
                              user: _vm.client.salesforceUser,
                              account: _vm.client.salesforceAccount,
                              entity: "client",
                            },
                          })
                        : _vm._e(),
                      _vm.isEcommerceContext
                        ? _c("url-alias-form", {
                            attrs: {
                              "url-alias": _vm.urlAlias,
                              options: _vm.urlCreatorOptions,
                              loading: _vm.loadingUrlAlias,
                            },
                            on: {
                              change: _vm.onChangeUrlAlias,
                              remove: _vm.onRemoveUrlAlias,
                            },
                          })
                        : _vm._e(),
                      _vm.isEcommerceContext && _vm.customImages
                        ? _c("custom-logos-form", {
                            attrs: {
                              "default-images": _vm.customImages,
                              loading: _vm.loadingUrlAlias,
                            },
                            on: { change: _vm.onImagesChange },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.showForm
                ? _c(
                    "div",
                    [
                      _c("card-form-loading"),
                      _c("card-form-loading", {
                        staticClass: "mt-10",
                        attrs: { rows: 4 },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between sm:px-3" },
              [
                _c("save-button", {
                  staticClass: "mb-3 sm:mb-0 button",
                  attrs: {
                    id: "user-submit",
                    variant: "pill",
                    disabled: _vm.loading,
                  },
                }),
                _c(
                  "sun-button",
                  {
                    staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                    attrs: { variant: "pill", color: "white" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("cancel")
                      },
                    },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }