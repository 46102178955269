import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import { ALIAS_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import { api } from '.';

export const getResources = clientId => ({
  [CLIENT_RESOURCE]: clientId,
  [ALIAS_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:UrlAlias[],meta:EndpointMeta}>}
 */
export async function getUrlAlias(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources(clientId));
  const { data } = await api.get(partialUrl, params.buildWithoutPage() || params);
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
