<template>
  <card-form>
    <template slot="title">Customize Logos</template>
    <template slot="form">
      <div v-if="loading">
        <card-form-loading></card-form-loading>
      </div>
      <div v-else class="w-full flex-col">
        <sun-label-group :key="`favicon_${img_key}`" text="Chose FavIcon">
          <div class="flex w-full">
            <div v-if="images && images.faviconPath" class="flex flex-col w-1/3">
              <img class="img-class" :src="images.faviconUrl" />
              <span class="text-xs mt-1 flex items-center cursor-pointer" @click="onFileUpload([], IMGTYPES.FAVICON)">
                Delete
                <close-svg class="w-3 h-3 ml-1 mb-1"
              /></span>
            </div>
            <sun-file-uploader id="file-favIcon" accept="image/*" @change="onFileUpload($event, IMGTYPES.FAVICON)" />
          </div>
        </sun-label-group>
        <sun-label-group :key="`light_${img_key}`" text="Chose Logo for light background" class="mt-4">
          <div class="flex w-full">
            <div v-if="images && images.backgroundLightPath" class="flex flex-col w-1/3 relative">
              <img class="img-class" :src="images.backgroundLightUrl" />
              <span class="text-xs mt-1 flex items-center cursor-pointer" @click="onFileUpload([], IMGTYPES.BG_LIGHT)">
                Delete
                <close-svg class="w-3 h-3 ml-1 mb-1"
              /></span>
            </div>
            <sun-file-uploader id="file-bg-light" accept="image/*" @change="onFileUpload($event, IMGTYPES.BG_LIGHT)" />
          </div>
        </sun-label-group>
        <sun-label-group :key="`dark_${img_key}`" text="Chose Logo for dark background" class="mt-4">
          <div class="flex w-full">
            <div v-if="images && images.backgroundDarkPath" class="flex flex-col w-1/3">
              <img class="img-class" :src="images.backgroundDarkUrl" />
              <span class="text-xs mt-1 flex items-center cursor-pointer" @click="onFileUpload([], IMGTYPES.BG_DARK)">
                Delete
                <close-svg class="w-3 h-3 ml-1 mb-1"
              /></span>
            </div>
            <sun-file-uploader id="file-bg-dark" accept="image/*" @change="onFileUpload($event, IMGTYPES.BG_DARK)" />
          </div>
        </sun-label-group>
      </div>
    </template>
  </card-form>
</template>

<script>
import CardForm from '@/components/atoms/CardForm';
import CardFormLoading from '@/components/atoms/CardFormLoading.vue';
import CloseSvg from '@/components/icons/CloseSvg';

const IMGTYPES = {
  FAVICON: 'FAVICON',
  BG_DARK: 'BG_DARK',
  BG_LIGHT: 'BG_LIGHT',
};
export default {
  name: 'CustomLogosForm',
  components: { CardForm, CardFormLoading, CloseSvg },
  props: {
    defaultImages: { type: Object, default: () => null },
    options: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      images: null,
      IMGTYPES,
      img_key: 0,
    };
  },
  mounted() {
    this.loadImages();
  },

  methods: {
    loadImages() {
      this.images = this.defaultImages;
    },
    onFileUpload(file, type) {
      switch (type) {
        case this.IMGTYPES.FAVICON:
          if (file[0]) {
            this.images.faviconImage = file[0];
          } else {
            this.images.faviconImage = null;
          }
          this.images.faviconPath = null;
          break;
        case this.IMGTYPES.BG_DARK:
          if (file[0]) {
            this.images.backgroundDarkImage = file[0];
          } else {
            this.images.backgroundDarkImage = null;
          }
          this.images.backgroundDarkPath = null;
          break;
        case this.IMGTYPES.BG_LIGHT:
          if (file[0]) {
            this.images.backgroundLightImage = file[0];
          } else {
            this.images.backgroundLightImage = null;
          }
          this.images.backgroundLightPath = null;
          break;
      }
      this.$forceUpdate();
      this.$emit('change', this.images);
    },
  },
};
</script>
<style>
.img-class {
  max-width: 120px;
  max-height: 120px;
}
</style>
