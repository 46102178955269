import { CONTEXTS } from '@/model/shared/contexts';
import { ClientShared } from './ClientShared';
import { ThirdPartyClient } from '@/entities/ThirdParty/ThirdPartyClient';
import { EcommerceClient } from '@/entities/ecommerce/EcommerceClient';
import { DashboardSSPClient } from '@/entities/dashboardSSP/DashboardSSPClient';
import { SocialAudienceClient } from '@/entities/socialAudience/SocialAudienceClient';
import { SunStudioClient } from '@/entities/sunStudio/SunStudioClient';
import { ContextualClient } from '../contextual/ContextualClient';

export default class ClientFactory {
  /**
   * Get a Client class based on context.
   * @param {string} contextId
   * @returns {ClientShared|ThirdPartyClient|EcommerceClient|DashboardSSPClient|SocialAudienceClient}
   */
  static create(contextId = CONTEXTS.CORE.id) {
    switch (contextId) {
      case CONTEXTS.CORE.id:
        return ClientShared;
      case CONTEXTS.THIRD_PARTY.id:
        return ThirdPartyClient;
      case CONTEXTS.ECOMMERCE.id:
        return EcommerceClient;
      case CONTEXTS.DASHBOARD_SSP.id:
        return DashboardSSPClient;
      case CONTEXTS.SOCIAL_AUDIENCE.id:
        return SocialAudienceClient;
      case CONTEXTS.SUNSTUDIO.id:
        return SunStudioClient;
      case CONTEXTS.CONTEXTUAL.id:
        return ContextualClient;
      default:
        throw Error(`There is no client for ${contextId} context.`);
    }
  }
}
