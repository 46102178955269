import { CLIENT_RESOURCE, ALIAS_RESOURCE } from '@/services/keys';
import { api } from '.';

export const getResources = cliendId => ({
  [CLIENT_RESOURCE]: cliendId,
  [ALIAS_RESOURCE]: undefined,
});

export async function createUrlAlias(clientId, urlAlias) {
  const partialUrl = api.createUrl(getResources(clientId));
  const {
    data: { clientAlias: id },
  } = await api.create(partialUrl, urlAlias);
  return id;
}
